import React from 'react';
import LayoutContainerLess from '../layout/layoutContainerLess';
import Seo from '../layout/seo';
import Home from '../content/home/home';

class Index extends React.Component {
  render() {
    //console.log(this.props);
    //console.log(i18n.language);
    return (
      
      
      <LayoutContainerLess location={this.props.location}>
      <Seo lang="de" title="Der Ernährungsplan für Sportlerinnen und Sportler" location={this.props.location} />
      <Home />
      </LayoutContainerLess>
      
    )
  }
}

export default Index;